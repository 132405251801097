<template>
  <standard>
    <Logo/>
    <section class="section container content">
      <h1>Privacy Statement</h1>

      <p>The following explanations outline what happens with your personally identifiable information when you visit our website. Personally identifiable information is the term used for all data that permits identification of you as a person. For detailed information about data protection, please refer to the privacy statement below.</p>
      
      <p>&nbsp;</p>
      
      <h2>Data recording on our website</h2>
      
      <h3>Who is responsible for collecting data on this website?</h3>
      <p>The website operator is responsible for processing data on this website. Their contact details can be found in the Legal Notices section of this website.</p>
      
      <h3>How do we collect your data?</h3>
      <p>Firstly we collect data that you provide to us. This may be information you enter in a contact form, for example.<br />Other data is collected automatically by our IT systems when you visit the website, and mainly includes technical data (e.g. internet browser, operating system or time of your visit). This data is collected automatically as soon as you load our website.</p>
      
      <h3>How do we use your data?</h3>
      <p>Some of the data is collected to assure our ability to provide a fully functioning website. Other data may be used to analyse your behaviour as a user.</p>
      
      <h3>What rights do you have in connection with your data?</h3>
      <p>You have the right – at any time and free of charge – to information about the origin, recipients and intended use of the personally identifiable information we hold on file. You also have the right to rectification, blockage or erasure of this data. For more information and if you have any queries about data protection, please feel free to contact the address indicated in the Legal Notices section at any time. You also have the right to complain to the relevant supervisory authority. Further, under certain circumstances you have the right to restriction of processing of your personally identifiable information. For more details, please refer to the section “Right to restriction of processing” in this privacy statement.</p>
      
      <h3>Analysis tools and tools provided by third parties</h3>
      <p>There is a possibility that your browsing patterns will be statistically analysed when your visit our website. Such analyses are performed primarily with cookies and with what we refer to as analysis programmes. As a rule, the analyses of your browsing patterns are conducted anonymously; i.e. the browsing patterns cannot be traced back to you.<br />You have the option to object to such analyses or you can prevent their performance by not using certain tools. For detailed information about the tools and about your options to object, please consult our Data Protection Declaration below.</p>
      
      <p>&nbsp;</p>
      
      <h2>Hosting</h2>
      <p>This website is hosted by an external service provider (host). Personal data collected on this website are stored on the servers of the host. These may include, but are not limited to, IP addresses, contact requests, metadata and communications, contract information, contact information, names, web page access, and other data generated through a web site.</p>
      <p>The host is used for the purpose of fulfilling the contract with our potential and existing customers (Art. 6 para. 1 lit. b DSGVO) and in the interest of secure, fast and efficient provision of our online services by a professional provider (Art. 6 para. 1 lit. f DSGVO).</p>
      <p>Our host will only process your data to the extent necessary to fulfil its performance obligations and to follow our instructions with respect to such data.</p>
      <p><strong>Execution of a contract data processing agreement</strong><br />In order to guarantee processing in compliance with data protection regulations, we have concluded an order processing contract with our host.</p>
      
      <p>&nbsp;</p>
      
      <h2>General and mandatory information</h2>
      
      <h3>Data protection</h3>
      <p>The operators of this website and its pages take the protection of your personal data very seriously. Hence, we handle your personal data as confidential information and in compliance with the statutory data protection regulations and this Data Protection Declaration.</p>
      <p>Whenever you use this website, a variety of personal information will be collected. Personal data comprises data that can be used to personally identify you. This Data Protection Declaration explains which data we collect as well as the purposes we use this data for. It also explains how, and for which purpose the information is collected.</p>
      <p>We herewith advise you that the transmission of data via the Internet (i.e. through e-mail communications) may be prone to security gaps. It is not possible to completely protect data against third party access.</p>
      <p>Data Processing Controller for this website is:<br />BAUDERFILM GMBH<br />Görlitzerstr. 53<br />D- 10997 Berlin Germany</p>
      <p>Phone: +49 (0)30 420 872 32<br /> E-mail: info@bauderfilm.de</p>
      <p>The controller is the natural person or legal entity that single-handedly or jointly with others makes decisions as to the purposes of and resources for the processing of personal data (e.g. names, e-mail addresses, etc.).</p>
      
      <h3>Revocation of your consent to the processing of data</h3>
      <p>A wide range of data processing transactions are possible only subject to your express consent. You can also revoke at any time any consent you have already given us. To do so, all you are required to do is sent us an informal notification via e-mail. This shall be without prejudice to the lawfulness of any data collection that occurred prior to your revocation.</p>
      
      <h3>Right to object to the collection of data in special cases; right to object to direct advertising (Art. 21 GDPR)</h3>
      <p>In the event that data are processed on the basis of Art. 6 Sect. 1 lit. e or f GDPR, you have the right to at any time object to the processing of your personal data based on grounds arising from your unique situation. This also applies to any profiling based on these provisions. To determine the legal basis, on which any processing of data is based, please consult this Data Protection Declaration. If you log an objection, we will no longer process your affected personal data, unless we are in a position to present compelling protection worthy grounds for the processing of your data, that outweigh your interests, rights and freedoms or if the purpose of the processing is the claiming, exercising or defence of legal entitlements (objection pursuant to Art. 21 Sect. 1 GDPR).</p>
      <p>If your personal data is being processed in order to engage in direct advertising, you have the right to at any time object to the processing of your affected personal data for the purposes of such advertising. This also applies to profiling to the extent that it is affiliated with such direct advertising. If you object, your personal data will subsequently no longer be used for direct advertising purposes (objection pursuant to Art. 21 Sect. 2 GDPR).</p>
      
      <h3>Right to log a complaint with the competent supervisory agency</h3>
      <p>In the event of violations of the GDPR, data subjects are entitled to log a complaint with a supervisory agency, in particular in the member state where they usually maintain their domicile, place of work or at the place where the alleged violation occurred. The right to log a complaint is in effect regardless of any other administrative or court proceedings available as legal recourses.</p>
      
      <h3>Right to data portability</h3>
      <p>You have the right to demand that we hand over any data we automatically process on the basis of your consent or in order to fulfil a contract be handed over to you or a third party in a commonly used, machine readable format. If you should demand the direct transfer of the data to another controller, this will be done only if it is technically feasible.</p>
      
      <h3>SSL and/or TLS encryption</h3>
      <p>For security reasons and to protect the transmission of confidential content, such as purchase orders or inquiries you submit to us as the website operator, this website uses either an SSL or a TLS encryption programme. You can recognise an encrypted connection by checking whether the address line of the browser switches from “http://” to “https://” and also by the appearance of the lock icon in the browser line.</p>
      <p>If the SSL or TLS encryption is activated, data you transmit to us cannot be read by third parties.</p>
      
      <h3>Information about, blockage, rectification and eradication of data</h3>
      <p>Within the scope of the applicable statutory provisions, you have the right to at any time demand information about your archived personal data, their source and recipients as well as the purpose of the processing of your data. You may also have a right to have your data rectified, blocked or eradicated. If you have questions about this subject matter or any other questions about personal data, please do not hesitate to contact us at any time at the address provided in section “Information Required by Law.”</p>
      
      <h3>Right to restriction of processing</h3>
      <p>You have the right to demand the imposition of restrictions as far as the processing of your personal data is concerned. To do so, you may contact us at any time at the address provided in section “Information Required by Law.” The right to demand restriction of processing applies in the following cases:</p>
      <ul>
      <li>In the event that you should dispute the correctness of your data archived by us, we will usually need some time to verify this claim. During the time that this investigation is ongoing, you have the right to demand that we restrict the processing of your personal data.</li>
      <li>If the processing of your personal data was/is conducted in an unlawful manner, you have the option to demand the restriction of the processing of your data in lieu of demanding the eradication of this data.</li>
      <li>If we do not need your personal data any longer and you need it to exercise, defend or claim legal entitlements, you have the right to demand the restriction of the processing of your personal data instead of its eradication.</li>
      <li>If you have raised an objection pursuant to Art. 21 Sect. 1 GDPR, your rights and our rights will have to be weighed against each other. As long as it has not been determined whose interests prevail, you have the right to demand a restriction of the processing of your personal data.</li>
      </ul>
      <p>If you have restricted the processing of your personal data, these data – with the exception of their archiving – may be processed only subject to your consent or to claim, exercise or defend legal entitlements or to protect the rights of other natural persons or legal entities or for important public interest reasons cited by the European Union or a member state of the EU.</p>
      
      <h2>Rejection of unsolicited e-mails</h2>
      <p>We herewith object to the use of contact information published in conjunction with the mandatory information to be provided in section “Information Required by Law” to send us promotional and information material that we have not expressly requested. The operators of this website and its pages reserve the express right to take legal action in the event of the unsolicited sending of promotional information, for instance via SPAM messages.</p>
      
      <p>&nbsp;</p>
      
      <h2>Recording of data on our website</h2>
      
      <h3>Server log files</h3>
      <p>The provider of this website and its pages automatically collects and stores information in so-called server log files, which your browser communicates to us automatically. The information comprises:</p>
      <ul>
      <li>The type and version of browser used</li>
      <li>The used operating system</li>
      <li>Referrer URL</li>
      <li>The hostname of the accessing computer</li>
      <li>The time of the server inquiry</li>
      <li>The IP address</li>
      </ul>
      <p>This data is not merged with other data sources.</p>
      <p>This data is recorded on the basis of Art. 6 Sect. 1 lit. f GDPR. The operator of the website has a legitimate interest in the technically error free depiction and the optimization of the operator’s website. In order to achieve this, server log files must be recorded.</p>
      
      <h3>Request by e-mail, telephone or fax</h3>
      <p>If you contact us by e-mail, telephone or fax, your request, including all resulting personal data (name, request) will be stored and processed by us for the purpose of processing your request. We do not pass these data on without your consent.</p>
      <p>The processing of these data is based on Art. 6 para. 1 lit. b GDPR, if your request is related to the execution of a contract or if it is necessary to carry out pre-contractual measures. In all other cases, the processing is based on your consent (Article 6 (1) a GDPR) and/or on our legitimate interests (Article 6 (1) (f) GDPR), since we have a legitimate interest in the effective processing of requests addressed to us.</p>
      <p>The data sent by you to us via contact requests remain with us until you request us to delete, revoke your consent to the storage or the purpose for the data storage lapses (e.g. after completion of your request). Mandatory statutory provisions - in particular statutory retention periods - remain unaffected.</p>
    </section>
  </standard>
</template>

<script>
  import Standard from '../layouts/standard.vue'
  import Logo from '@/components/logo.vue'

  export default {
    components: {
      Standard, Logo
    }
  }
</script>
