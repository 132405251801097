<template>
  <standard>
    <section class="section container content has-text-centered">
      <h1>WHO WE WERE</h1>
      <p class="notification is-danger is-light">Page not found</p>
      <p><a href="/" class="button is-black">Back to Home</a></p>
    </section>
  </standard>
</template>

<script>
  import Standard from '../layouts/standard.vue'

  export default {
    components: {
      Standard
    }
  }
</script>
