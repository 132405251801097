<template>
  <standard>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>

    <section id="intro" class="hero is-fullheight is-small">
      <div class="hero-head is-hidden-touch">
        <div class="level actors">
          <div class="level-item is-narrow has-text-centered">
            <p>
              <strong class="has-text-white is-block">Alexander Gerst</strong>
              Astronaut
            </p>
          </div>
          <div class="level-item is-narrow has-text-centered">
            <p>
              <strong class="has-text-white is-block">Sylvia Earle</strong>
              Oceanologist
            </p>
          </div>
          <div class="level-item is-narrow has-text-centered">
            <p>
              <strong class="has-text-white is-block">Dennis Snower</strong>
              Economist
            </p>
          </div>
          <div class="level-item is-narrow has-text-centered">
            <p>
              <strong class="has-text-white is-block">Felwine Sarr</strong>
              Philosopher
            </p>
          </div>
          <div class="level-item is-narrow has-text-centered">
            <p>
              <strong class="has-text-white is-block">Janina Loh</strong>
              Critical Post-Humanist
            </p>
          </div>
          <div class="level-item is-narrow has-text-centered">
            <p>
              <strong class="has-text-white is-block">Matthieu Ricard</strong>
              Buddhist Monk
            </p>
          </div>
        </div>
      </div>
      
      <div class="hero-body">
        <div class="columns">
          <div class="column">
            <div class="eye"></div>
            <div class="logos">
              <b-image :src="require('@/assets/logo-berlinale-special-2021.svg')" alt="" class="berlinale" ratio="240by100"></b-image>
            </div>
          </div>
          <div class="column">
            <div class="brand">
              <b-image :src="require('@/assets/logo-whowewere.svg')" alt=""></b-image>
            </div>
          </div>
        </div>
      </div>

      <div class="hero-foot">
        <div class="buttons is-centered">
          <a href="#trailer" class="button is-black">Trailer</a>
          <a href="#info" class="button is-black is-outlined">Synopsis</a>
          <a href="#actors" class="button is-black is-outlined">Protagonists</a>
        </div>
      </div>
    </section>

    <section id="trailer" class="section container">
      <figure class="image is-16by9 has-background-black">
        <img src="@/assets/vimeo-poster.jpg" alt="" @click="showYouTube('#vimeo')" style="z-index: 1; cursor: pointer;"/>
        <iframe id="vimeo" class="has-ratio" width="560" height="315" data-src="https://player.vimeo.com/video/476212594?autoplay=1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </figure>
      <p class="has-text-centered is-size-7 mt-2">By playing the video, you accept <a href="https://vimeo.com/privacy" target="_blank" rel="noopener noreferrer">Vimeo's privacy policy</a></p>
    </section>

    <section id="info" class="section container content">
      <blockquote>
        <p>„We were the ones who knew, but did not understand, full of information but lacking insight, brimming with knowledge, but lacking experience. So we just kept forging ahead, unstopped by ourselves.“</p>
        <cite>Roger Willemsen</cite>
      </blockquote>
      <p>What will future generations think of us in a historical context? In the spirit of Willemsen's legacy, will they look back on us with despair? The film WHO WE WERE observes the current state of the world, accompanied by six intellectuals and scientists who reflect on the present and postulate about the future.</p>
      <p>Producer, director and writer Marc Bauder follows his interviewees into the depths of the ocean, to the top of the world, and out into the far reaches of space. Together, they explore the incredible capabilities of the human brain, a global economic summit, the legacy of colonisation, and the feelings of a robot.</p>
      <p>This inspiring look at our world offers a hopeful message for us all: Who we are is in our own hands, if we recognise our connectedness, our commonality, and the meaning of 'we'.</p>
      <p>WHO WE WERE is an intense encounter with six important contemporary thinkers and scientists: Alexander Gerst (astronaut), Dennis Snower (economist), Matthieu Ricard (molecular biologist and monk), Sylvia Earle (oceanologist), Felwine Sarr (economist, sociologist and philosopher) and Janina Loh (philosopher and critical posthumanist).</p>

      <p class="buttons is-centered">
        <a href="downloads/Pressbook-WHO-WE-WERE.pdf" target="_blank" rel="noopener" class="button is-black">Press book (PDF)</a>
        <a href="downloads/Poster-WHO-WE-WERE.pdf" target="_blank" rel="noopener" class="button is-black is-outlined">Poster (PDF)</a>
        <a href="downloads/Poster-WHO-WE-WERE.jpg" target="_blank" rel="noopener" class="button is-black is-outlined">Poster (JPG)</a>
      </p>
    </section>

    <section id="carousel" class="section container">
      <b-carousel>
        <b-carousel-item v-for="(carousel, i) in carousels" :key="i">
          <b-image :src="carousel.image" alt="" ratio="5by2"></b-image>
          <span v-if="carousel.title" :class="'carousel-item-title carousel-item-title-' + carousel.align"><strong>{{ carousel.title }}</strong> {{ carousel.subtitle }}</span>
        </b-carousel-item>
      </b-carousel>
    </section>

    <section id="actors" class="section container content">
        <h2 class="is-size-2">Protagonists</h2>
        <b-collapse class="card" animation="slide" :open="false">
            <template #trigger="props">
              <div class="card-header" role="button">
                <span class="card-header-title">ALEXANDER GERST</span>
                <a class="card-header-icon"><b-icon size="is-medium" :icon="props.open ? 'chevron-up' : 'chevron-down'"></b-icon></a>
              </div>
            </template>
            <div class="card-content content">
              <div class="columns">
               <div class="column is-4">
                <b-image :src="require('@/assets/carousel/Alexander-Gerst-1by1.jpg')" alt="" ratio="1by1"></b-image>
               </div>
               <div class="column">
                <p class="has-text-white">Astronaut</p>
                <p><em>„As humans, we’re realising that if we destroy our planet, and that is easily possible if you look at the atmosphere (...), then it's over for us: the history of humankind ends.“</em></p>
                <p>Before exploring the vastness of space, astronaut Alexander Gerst (b. 1976) spent years as a geophysicist investigating the powerful forces inside the Earth. The title of his dissertation at the University of Hamburg was "The first second of a Strombolian volcanic eruption". In 2009, he began his training as an astronaut at the European Space Agency ESA in Cologne, Germany.</p>
                <p>In 2014 and 2018, Alexander Gerst was involved in missions to the International Space Station (ISS), and took command of the ISS for three months on his second trip. He spent a total of 363 days in space. His images and impressions show us time and again how unique, beautiful, and vulnerable our home planet is: "Spaceship Earth".</p>
                <p>Gerst has received many awards for his activism and is a two-time recipient of the German Bundesverdienstkreuz (Federal Cross of Merit). As a UNICEF ambassador, he campaigns for the rights and opportunities of children all over the world.</p>
               </div>
              </div>
            </div>
        </b-collapse>
        <b-collapse class="card" animation="slide" :open="false">
            <template #trigger="props">
              <div class="card-header" role="button">
                <span class="card-header-title">SYLVIA EARLE</span>
                <a class="card-header-icon"><b-icon size="is-medium" :icon="props.open ? 'chevron-up' : 'chevron-down'"></b-icon></a>
              </div>
            </template>
            <div class="card-content content">
              <div class="columns">
               <div class="column is-4">
                <b-image :src="require('@/assets/carousel/Sylvia-Earle-1by1.jpg')" alt="" ratio="1by1"></b-image>
               </div>
               <div class="column">
                <p class="has-text-white">Oceanologist</p>
                <p><em>„Since there are nearly eight billion of us, all our decisions matter – be they about what we eat, what we wear, what kind of energy we use, or how we waste water.“</em></p>
                <p>Sylvia Earle (b. 1935), called a "Hero for the Planet" by Time magazine, is an American oceanographer, explorer, author, and lecturer. At the age of three, she experienced a pivotal moment that has driven her to tirelessly follow her vocation to this day: she was caught in a wave of the Atlantic Ocean and almost lost her life. This drastic experience was the initial spark for her lifelong fascination and passion to explore and protect the world's oceans. In 1979, the US biologist became the first person to walk on the bottom of the Atlantic Ocean, 381 metres underwater in pressurised diving gear. Seven years later, she became one of the first test pilots to reach a depth of 1,000 metres in a submersible.</p>
                <p>In her research career, the "Grand Dame of the Deep" has led more than 100 ocean expeditions and spent at least 7,000 hours underwater. Earle's decades of experience in penetrating the unknown depths of the world's oceans with ever new technical means have earned her, among other things, a consulting contract with NASA. Yet it is still incomprehensible to her that planets like Mars and the Moon are now much better mapped than the oceans – even though they occupy 95 per cent of the Earth's entire biosphere. Today, as an ambassador for the oceans with her organisation Mission Blue, she is raising public awareness and much-needed support for the conservation of protected marine areas, the so-called Hope Spots.</p>
               </div>
              </div>
            </div>
        </b-collapse>
        <b-collapse class="card" animation="slide" :open="false">
            <template #trigger="props">
              <div class="card-header" role="button">
                <span class="card-header-title">DENNIS SNOWER</span>
                <a class="card-header-icon"><b-icon size="is-medium" :icon="props.open ? 'chevron-up' : 'chevron-down'"></b-icon></a>
              </div>
            </template>
            <div class="card-content content">
              <div class="columns">
               <div class="column is-4">
                <b-image :src="require('@/assets/carousel/Dennis-Snower-1by1.jpg')" alt="" ratio="1by1"></b-image>
               </div>
               <div class="column">
                <p class="has-text-white">Economist</p>
                <p><em>„I think it’s really important for children to grow up in a system that raises a red flag every time phrases crop up like "America first," "Build a wall," or "Foreigners out!" They should immediately realise, "Ah, that's just the opposite of what we need." But hardly anyone in the Western world is educated like that. Hardly anyone knows which narratives promote cooperation and which ones lead to conflict. And that's why our education systems need to teach the value of cooperation to solve the important problems affecting all societies.“</em></p>
                <p>Dennis Snower (b. 1950) studied Philosophy, Political Science, and Economics at Oxford University and holds a PhD in Economics from Princeton University. He was once a classic advocate of neoliberalism and its "homo economicus" – a person who first and foremost wants to maximise their profit. Today, however, he advocates for a paradigm shift and demands that the global economy should no longer be permitted to ignore insights from ecology, psychology, neuroscience, anthropology, and sociology, but instead be obliged to assume greater social responsibility.</p>
                <p>In his 15-year presidency at the helm of the Kiel Institute for the World Economy (IfW), Austrian-born Dennis Snower initiated the "World Economy Prize", an award that the IfW presents annually to thought leaders of a cosmopolitan, free-market, and social society.</p>
                <p>In 2019, Dennis Snower left the Kiel Institute and has since been in active retirement. He still heads the international think-tank network Global Solutions Initiative as president, which he founded in 2019. The initiative has become a kind of intellectual backbone for the G20, promoting the solution of global problems through active exchange between academia, politics, business, and civil society. He is also a professor at the Hertie School of Governance in Berlin and a researcher at Oxford University, Brookings Institution in Washington D.C., and The New Institute in Hamburg.</p>
                <p>Dennis Snower, who also writes poetry and has performed as a classical pianist, is a visiting professor at numerous prestigious universities and advises international organisations and governments.</p>
               </div>
              </div>
            </div>
        </b-collapse>
        <b-collapse class="card" animation="slide" :open="false">
            <template #trigger="props">
              <div class="card-header" role="button">
                <span class="card-header-title">MATTHIEU RICARD</span>
                <a class="card-header-icon"><b-icon size="is-medium" :icon="props.open ? 'chevron-up' : 'chevron-down'"></b-icon></a>
              </div>
            </template>
            <div class="card-content content">
              <div class="columns">
               <div class="column is-4">
                <b-image :src="require('@/assets/carousel/Matthieu-Ricard-1by1.jpg')" alt="" ratio="1by1"></b-image>
               </div>
               <div class="column">
                <p class="has-text-white">Buddhist & molecular biologist</p>
                <p><em>„True freedom is to take the helm, hoist the sails, and steer wherever you want to go. The freedom to reach your destination.“</em></p>
                <p>Matthieu Ricard (b. 1946), son of the artist Yahne Le Toumelin and Jean-François Revel, one of France's leading intellectuals, studied Molecular Biology and earned his doctorate under Nobel Prize winner François Jacob. An impressive career in France followed, until 1970, when Ricard turned his back on classic science to follow a spiritual path as a Buddhist monk in Nepal.</p>
                <p>As a member of the Mind & Life Institute, an organisation dedicated to the dialogue between research and Buddhism, he worked with scientists in the field of brain research on the effects of meditation on the human brain. This is probably one of the reasons why his brain is one of the best researched in the world – Ricard spent hundreds of hours as a test subject in the MRI. The finding: fundamental human qualities such as unselfishness, inner peace, and emotional balance can, like many other things, actually be trained.</p>
                <p>Matthieu Ricard is the official French translator of the Dalai Lama, as well as a photographer and author of numerous bestsellers. In his books, he deals with the orientation of the world economy towards greater togetherness, empathy, and consideration. He lives in seclusion at the foot of the Himalayas.</p>
               </div>
              </div>
            </div>
        </b-collapse>
        <b-collapse class="card" animation="slide" :open="false">
            <template #trigger="props">
              <div class="card-header" role="button">
                <span class="card-header-title">FELWINE SARR</span>
                <a class="card-header-icon"><b-icon size="is-medium" :icon="props.open ? 'chevron-up' : 'chevron-down'"></b-icon></a>
              </div>
            </template>
            <div class="card-content content">
              <div class="columns">
               <div class="column is-4">
                <b-image :src="require('@/assets/carousel/Felwine-Sarr-1by1.jpg')" alt="" ratio="1by1"></b-image>
               </div>
               <div class="column">
                <p class="has-text-white">Economist, sociologist, and philosopher</p>
                <p><em>„Ultimately, it's about building a human community, a global 'we'.“</em></p>
                <p>Felwine Sarr (b. 1972), who grew up on two continents in Strasbourg and Dakar, is considered one of Africa's most discussed and important thinkers. Born in Senegal, he is Professor of Economics at Gaston Berger University in Saint-Louis, and teaches Romance Studies at Duke University in the USA. His research interests include Political Economy, Macroeconomics and Development Economics. He also works as a social scientist scholar, book author, and musician.</p>
                <p>In his acclaimed work "Afrotopia" (2019), Felwine Sarr looks at the decolonisation of Africa in the wake of a return to the continent's intellectual resources, cultural heritage, and identity. He also explores the pressing question of how colonial ideas shape Europe's attitudes and actions towards Africa.</p>
                <p>In the same year, together with the French art historian Bénédicte Savoy, he was commissioned by French President Emmanuel Macron to prepare the return of looted art to Africa.</p>
                <p>Felwine Sarr is fundamentally concerned with the question of how a newly interpreted "we" can emerge between Europe and Africa. He also focuses on the consequences of the climate crisis and finding a concept for holistic prosperity that is oriented towards the needs of all.</p>
               </div>
              </div>
            </div>
        </b-collapse>
        <b-collapse class="card" animation="slide" :open="false">
            <template #trigger="props">
              <div class="card-header" role="button">
                <span class="card-header-title">JANINA LOH</span>
                <a class="card-header-icon"><b-icon size="is-medium" :icon="props.open ? 'chevron-up' : 'chevron-down'"></b-icon></a>
              </div>
            </template>
            <div class="card-content content">
              <div class="columns">
               <div class="column is-4">
                <b-image :src="require('@/assets/carousel/Janina-Loh-1by1.jpg')" alt="" ratio="1by1"></b-image>
               </div>
               <div class="column">
                <p class="has-text-white">Philosopher and critical post-humanist</p>
                <p><em>„If I imagine a society a hundred years from now looking back, I would hope that, as a society, we will have managed to move away from this exclusive thinking that puts people at the centre.“</em></p>
                <p>Janina Loh (b. 1984) is Philosopher of Technology and Critical Post-humanist at the University of Vienna. She received an award already for her Master's thesis at the Humboldt University in Berlin on Hannah Arendt’s concept of responsibility. Since then, the topic of "responsibility" has played a major role in Loh's research.</p>
                <p>In 2019, she published a paper on robot ethics, in which she explores the question of what ethical challenges we face in the construction, further development, and handling of robots. For Loh, the supremacy of data giants and advancing digitalisation are snapshots and problematic stages of development that need to be overcome on the way to an anti-capitalist future. Loh takes the position that technology is never neutral, as it is always created by us humans for a specific purpose. She believes that people cannot simply relinquish responsibility for their technologies.</p>
                <p>Her dissertation "Responsibility as a concept, capability and duty. A three-level analysis" (2014) was her first major study on responsibility. Loh's monograph on trans- and post-humanism (2018) is the first German-language introduction to the debate on these trends.</p>
               </div>
              </div>
            </div>
        </b-collapse>
    </section>

    <section id="sponsors" class="section container content">
      <div class="columns has-text-centered">
        <div class="column">
          <b-image :src="require('@/assets/siegel-pbw.png')" alt="" ratio="1by1"></b-image>
          <p>Awarded with the predicate PARTICULARLY VALUABLE by the German Film and Media Assessment, Wiesbaden.</p>
        </div>
        <div class="column">
          <b-image :src="require('@/assets/logo-berlinale-special-2021.svg')" alt="" ratio="1by1"></b-image>
          <p>Our film WHO WE WERE will be shown as a SPECIAL FEATURE at the 71st Berlin International Film Festival 2021.</p>
        </div>
      </div>
    </section>

    <section id="sales" class="section container content">
      <h2 class="is-size-2">International Sales</h2>
      <div class="columns is-centered mt-2">
        <div class="column is-half">
          <a href="https://filmsboutique.com/" target="_blank" rel="noopener">
            <b-image :src="require('@/assets/logo-filmsboutique.svg')" alt="" ratio="20by3"></b-image>
          </a>
        </div>
      </div>
      <p class="buttons is-centered">
        <a href="https://www.facebook.com/filmsboutique/" target="_blank" rel="noopener" class="button is-text"><b-icon icon="facebook" size="is-medium"></b-icon></a>
        <a href="https://twitter.com/filmsboutique" target="_blank" rel="noopener" class="button is-text"><b-icon icon="twitter" size="is-medium"></b-icon></a>
        <a href="https://www.instagram.com/filmsboutique/" target="_blank" rel="noopener" class="button is-text"><b-icon icon="instagram" size="is-medium"></b-icon></a>
      </p>
    </section>

    <section id="book" class="section container content">
      <h2 class="is-size-2">The book about the film</h2>
      <p class="has-text-centered" style="margin-top: -1rem;">German release April 2021 (further countries in the planning)</p>
      <div class="columns is-centered">
        <div class="column is-5">
          <a href="https://www.dorlingkindersley.de/buch/alexander-gerst-lars-abromeit-marc-bauder-matthieu-ricard-dennis-snower-sylvia-earle-janina-loh-felwine-sarrwer-wir-waren-das-buch-zum-film-9783831042197" target="_blank" rel="noopener" title="DK Verlag">
            <b-image :src="require('@/assets/book/cover.png')" alt="Book WHO WE WERE - DK Verlag" ratio="5by6"></b-image>
          </a>
        </div>
      </div>
      <div class="columns is-centered is-multiline">
        <div class="column is-half">
          <b-image :src="require('@/assets/book/page1.jpg')" alt="Book WHO WE WERE - DK Verlag - Page 1" ratio="5by3"></b-image>
        </div>
        <div class="column is-half">
          <b-image :src="require('@/assets/book/page2.jpg')" alt="Book WHO WE WERE - DK Verlag - Page 2" ratio="5by3"></b-image>
        </div>
        <div class="column is-half">
          <b-image :src="require('@/assets/book/page3.jpg')" alt="Book WHO WE WERE - DK Verlag - Page 3" ratio="5by3"></b-image>
        </div>
        <div class="column is-half">
          <b-image :src="require('@/assets/book/page4.jpg')" alt="Book WHO WE WERE - DK Verlag - Page 4" ratio="5by3"></b-image>
        </div>
        <div class="column is-half">
          <b-image :src="require('@/assets/book/page5.jpg')" alt="Book WHO WE WERE - DK Verlag - Page 5" ratio="5by3"></b-image>
        </div>
        <div class="column is-half">
          <b-image :src="require('@/assets/book/page6.jpg')" alt="Book WHO WE WERE - DK Verlag - Page 6" ratio="5by3"></b-image>
        </div>
      </div>
      <p class="has-text-centered">
        <a href="https://www.dorlingkindersley.de/buch/alexander-gerst-lars-abromeit-marc-bauder-matthieu-ricard-dennis-snower-sylvia-earle-janina-loh-felwine-sarrwer-wir-waren-das-buch-zum-film-9783831042197" target="_blank" rel="noopener" class="button is-black">Buy the book</a>
      </p>
    </section>
  </standard>
</template>

<script>
  import Standard from '../layouts/standard.vue'

  export default {
    components: {
      Standard
    },
    methods: {
      showYouTube : function(target) {
        var t = this.$el.querySelector(target);
        var src = t.dataset.src;
        if ( t.tagName == "IFRAME" && src != "undefined") {
          event.target.classList.add('is-hidden'); /* hide click trigger */
          t.src = src; /* set iframe source */
        }
      }
    },
    data() {
      return {
        isLoading: true,
        carousels: [
          { image: require('@/assets/carousel/Planet-Erde.jpg'), alt: '', align: 'center', title: '', subtitle: '' },
          { image: require('@/assets/carousel/Alexander-Gerst.jpg'), alt: '', align: 'center', title: 'Alexander Gerst', subtitle: '' },
          { image: require('@/assets/carousel/Sylvia-Earle.jpg'), alt: '', align: 'right', title: 'Sylvia Earle', subtitle: '' },
          { image: require('@/assets/carousel/Felwine-Sarr.jpg'), alt: '', align: 'left', title: 'Felwine Sarr', subtitle: '' },
          { image: require('@/assets/carousel/Taucher.jpg'), alt: '', align: 'right', title: '', subtitle: '' },
          { image: require('@/assets/carousel/Dennis-Snower.jpg'), alt: '', align: 'right', title: 'Dennis Snower', subtitle: '' },
          { image: require('@/assets/carousel/Janina-Loh.jpg'), alt: '', align: 'left', title: 'Janina Loh', subtitle: '' },
          { image: require('@/assets/carousel/Matthieu-Ricard.jpg'), alt: '', align: 'right', title: 'Matthieu Ricard', subtitle: '' },
          { image: require('@/assets/carousel/Angela-Merkel.jpg'), alt: '', align: 'right', title: '', subtitle: '' }
        ]
      }
    },
    mounted() {
      setTimeout(() => {
        this.isLoading = false}, 1000) /* hide loading after a second */
      }
  }
</script>
