<template>
  <standard>
    <Logo/>
    <section class="section container content">
      <h1>Imprint</h1>

      <p>Information pursuant to Section 5 of the German Teleservices Act (TMG)</p>
      <p>BAUDERFILM GMBH<br />Görlitzerstr. 53<br />D- 10997 Berlin Germany</p>
      <p>Phone: +49 (0)30 420 872 32<br /> E-mail: info@bauderfilm.de</p>
      <p>Represented by: Marc Bauder</p>
      <p>Commercial register: F02B, Finanzamt Berlin-Kreuzberg<br />VAT no.: DE 321356604</p>
      
      <p>&nbsp;</p>

      <p>The use of the contact data published in conjunction with the mandatory information requirements by third parties for the transmission of advertising and information materials which have not been expressly requested is not permitted. The operators of the websites expressly reserve the right to take legal steps in the case of the unsolicited submission of advertising information, for example by means of spam emails.</p>
      
      <p>&nbsp;</p>

      <h2>Liability for Contents</h2>
      <p>The contents of our website have been created with extreme care. However, we cannot provide any guarantee of the accuracy, completeness or up-to-dateness of the contents. As a service provider, we are responsible according to Section 7 para 1 of the German Teleservices Act (TMG) for our own content on these pages in accordance with general laws. However, according to Sections 8 to 10 TMG we are not obliged to monitor third-party information which is transmitted or stored or to investigate circumstances which indicate illegal activity. This does not affect the obligations to remove or block the use of information in accordance with general law. However, liability in this respect is only possible from the time of our becoming aware of a specific violation of the law. Upon becoming aware of such violations of the law, we will remove such content immediately.</p>
      
      <h2>Liability for Links</h2>
      <p>Our website contains links to the external websites of third parties, over whose contents we have no control. For this reason we cannot accept any liability for such third-party content. In all cases it is the respective provider or operator of the website who is responsible for the contents of the linked pages. At the time the links were placed on our website, a check was carried out with respect to possible legal violations. At the time the links were placed on our website, no illegal content was found. However, we cannot reasonably be expected to carry out permanent checks of the contents of the linked pages without specific evidence for a legal infringement. Upon becoming aware of any legal infringements we will immediately remove such links.</p>
      
      <h2>Copyright</h2>
      <div>The contents and articles created by the website operators on these pages are subject to German copyright law. The duplication, processing, distribution and any form of exploitation outside the restrictions imposed by copyright law require the written consent of the respective author or creator. Downloads and copies of this website are only permitted for private and non-commercial use. Insofar as the contents on this site have not been created by the operator, the copyrights of third parties apply. In particular, the contents of third parties are identified as such. Nevertheless, if you become aware of a copyright infringement, we request that you inform us thereof accordingly. Upon becoming aware of violations of the law, we shall remove such contents immediately.<br />FILIGRAN<sup>®</sup> is a registered trademark of FILIGRAN Trägersysteme GmbH &amp; Co. KG.</div>
    </section>
  </standard>
</template>

<script>
  import Standard from '../layouts/standard.vue'
  import Logo from '@/components/logo.vue'

  export default {
    components: {
      Standard, Logo
    }
  }
</script>
