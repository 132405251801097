import Vue from 'vue'
import Buefy from 'buefy'

import routes from './routes'
import errorView from './pages/404.vue'

Vue.config.productionTip = false
Vue.use(Buefy)

const app = new Vue({
  el: '#app',
  data: {
    currentRoute: window.location.pathname
  },
  computed: {
    ViewComponent () {
      const base = process.env.BASE_URL.slice(0, -1)
      const route = this.currentRoute.replace(base, '')

      const matchingView = routes[route]
      return matchingView ? matchingView : errorView
    }
  },
  render (h) {
    return h(this.ViewComponent)
  }
})

window.addEventListener('popstate', () => {
  app.currentRoute = window.location.pathname
})

/* Smooth scroll */
const links = document.querySelectorAll('a[href^="#"]');

for (const link of links) {
  link.addEventListener("click", clickHandler);
}

function clickHandler(e) {
  e.preventDefault();
  const href = this.getAttribute("href");
  const offsetTop = document.querySelector(href).offsetTop;

  scroll({
    top: offsetTop,
    behavior: "smooth"
  });
}
